<template>
  <span class="regular-12 text-black text-start">
    <span class="px-2">
      {{value}}
    </span>
  </span>
</template>

<script>
export default {
  name: "Serial",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: String,
  },
  data() {
    return {
      show: false,
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
